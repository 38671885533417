// Iets met paden naar de juiste icons icm webpacker:
// https://github.com/axyjo/leaflet-rails/issues/93#issuecomment-673320198

require('leaflet/dist/leaflet.css')

import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Leaflet Gesture Handling toevoegen (ctrl + scrollen voor zoomen)
import { GestureHandling } from "leaflet-gesture-handling";
require('leaflet-gesture-handling/dist/leaflet-gesture-handling.css')
L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);